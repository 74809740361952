import { ExecutionTaskType } from "../model/OG/ExecutionTaskType";
import { IncidentAdditionalActivationDTO } from "../model/OG/IncidentAdditionalActivation";
import { OGExecutionAdditionalActivationInfo } from "../model/OG/OGExecutionAdditionalActivationsInfo";

export const IncidentAADTOToOGExecutiondAAInfo = ({
  id,
  activationTimestamp,
  operationIncidentId,
  resourceMappingId,
}) => ({
  id,
  creationTimeStamp: activationTimestamp,
  clearedTimeStamp: activationTimestamp,
  type: ExecutionTaskType.AdditionalActivation,
  resourceMappingId,
  operationIncidentId,
  user: null,
  heading: null,
  icon: null,
});

export const IncidentAADTOToOGExecutiondAAInfoLists = (
  incidentAdditionalActivationDTO: IncidentAdditionalActivationDTO[]
): OGExecutionAdditionalActivationInfo[] =>
  incidentAdditionalActivationDTO.map(IncidentAADTOToOGExecutiondAAInfo);

export const isTaskOutsideOGConfiguration = (executionTaskType: string) =>
  executionTaskType === ExecutionTaskType.GenericInfo ||
  executionTaskType === ExecutionTaskType.AdditionalActivation ||
  executionTaskType === ExecutionTaskType.RecordedVideo;
