import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Spinner from "@msi/cobalt-react/spinner";
import {
  FloorPlanDevice,
  LocalizationNS,
  Site,
  SiteFloorPlan,
  useI18n,
} from "compass-commons";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { CommonIdentifier } from "../../model/CommonIdentifier";
import { AssignedIncidentResponse } from "../../model/incident/AssignedIncidentResponse";
import { ResourceMappingStateChangeData } from "../../model/notifications/ResourceMappingStateChangeData";
import { ResourceCommandAdditionalInfo } from "../../model/resource/ResourceCommand";
import ErrorBoundary from "../../errors/ErrorBoundary";
import MapService from "../../services/MapService";
import CompassDropdown from "../commons/compassDropdown/CompassDropdown";
import "./mapZone.module.css";

const MapBoxComponent = loadable(() => import("./MapBox"));

interface MapZoneProps {
  mapSiteId?: string;
  isTest?: boolean;
  resourceMappingId?: string;
  assignedIncident: AssignedIncidentResponse;
}

const { RESOURCE_MAPPING_STATE_CHANGE_HUB, MAPBOX_TOKEN } = appConfig;

const MapZone = (props: MapZoneProps) => {
  const { mapSiteId, isTest, resourceMappingId, assignedIncident } = props;
  const { t: translate } = useI18n();
  const { listenerService, stateService } = useGlobalContext();
  const [isLoading, setLoading] = useState(false);
  const [floors, setFloors] = useState<CommonIdentifier[]>(null);
  const [selectedFloorPlan, setSelectedFloorPlan] =
    useState<SiteFloorPlan>(null);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [resourceMappingStateChange, setResourceMappingStateChange] =
    useState<ResourceMappingStateChangeData>(null);
  const [isGeo, setIsGeo] = useState(false);
  const [selectedFloorPlanDevice, setSelectedFloorPlanDevice] =
    useState<FloorPlanDevice>(null);
  const [imageInfo, setImageInfo] = useState<{
    url: string;
    width: number;
    height: number;
  }>(null);
  const [showLabels, setShowLabels] = useState(false);
  const { alertSubject } = stateService;

  const [resourceCommandAdditionalInfo, setResourceCommandAdditionalInfo] =
    useState<ResourceCommandAdditionalInfo>({
      sourceName:
        assignedIncident?.triggerType === "ON-DEMAND"
          ? assignedIncident.triggerType
          : assignedIncident?.resourceMappingName,
      siteName: assignedIncident?.siteName,
      behaviorName: assignedIncident?.incidentDescription,
    });

  const GEO_VALUE = "geo";

  const getOptions = (floorPlanList: CommonIdentifier[]) => {
    const list: CommonIdentifier[] = floorPlanList;
    if (list) {
      const sortingIdentifier = (a, b) => (a.id as number) - (b.id as number);
      list.sort(sortingIdentifier);
    }
    let optionsList: unknown[] = list?.map((identifier) => ({
      value: identifier.id,
      label: identifier.name,
    }));

    optionsList = [
      {
        label: translate("map.siteLayout.geoMap"),
        options: [
          {
            value: GEO_VALUE,
            label: translate("map.siteLayout.mapView"),
          },
        ],
      },
      { label: translate("map.siteLayout.floorPlans"), options: optionsList },
    ];

    return optionsList;
  };

  const handleShowLabels = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setShowLabels(ev.target.checked);
  };

  const loadSelectedFloorPlan = (floorId) => {
    if (floorId && mapSiteId) {
      setLoading(true);
      MapService.getFloorPlanWithMedia(mapSiteId, floorId)
        .then((result) => {
          setSelectedFloorPlan(result.site);
          setLoading(false);
          setImageInfo(result.imageMetaData);
        })
        .catch(() => {
          setLoading(false);
          setSelectedFloorPlan({} as SiteFloorPlan);
        });
    }
  };

  const handleSelectFloor = (floor) => {
    loadSelectedFloorPlan(floor.id);
  };

  const selectLocation = (fp: CommonIdentifier, _options) => {
    setSelectedLocation(
      fp
        ? {
            value: fp.id,
            label: fp.name,
          }
        : {
            value: "geo",
            label: translate("map.siteLayout.mapView"),
          }
    );
  };

  const selectDefaultFloorPlan = (mapSite: Site, options) => {
    if (mapSite?.floorPlanList?.length > 0) {
      const initialFloor = mapSite.floorPlanList
        .sort((a, b) => a.name.localeCompare(b.name))
        .at(0);
      handleSelectFloor(initialFloor);
      selectLocation(initialFloor, options);
    }
  };

  const fetchFloorPlan = async (mapSite: Site, options) => {
    if (resourceMappingId) {
      const device = await MapService.getDeviceByResourceMappingId(
        mapSiteId,
        resourceMappingId
      );

      if (device) setSelectedFloorPlanDevice(device);

      if (device && device.deviceOnMap) {
        selectLocation(null, options);
        setIsGeo(true);
        return;
      }
    }

    if (
      (resourceMappingId || assignedIncident.floorPlanId) &&
      mapSite.floorPlanList?.length > 0
    ) {
      try {
        const deviceFloorPlans =
          !resourceMappingId && assignedIncident.floorPlanId
            ? ([
                await MapService.getFloorPlanById(
                  mapSiteId,
                  assignedIncident.floorPlanId
                ),
              ] as SiteFloorPlan[]) // On-demand incident floorplan
            : await MapService.getFloorPlanByDeviceId(
                mapSiteId,
                resourceMappingId
              ); // Default incident floorplans

        if (deviceFloorPlans?.length > 0) {
          const initialFloor = mapSite?.floorPlanList?.find(
            (fp) => String(fp?.id) === String(deviceFloorPlans[0]?.id)
          );
          loadSelectedFloorPlan(initialFloor?.id);
          setResourceCommandAdditionalInfo({
            ...resourceCommandAdditionalInfo,
            sourceFloorPlanName: initialFloor.name,
          });
          selectLocation(initialFloor, options);
          return;
        }
      } catch (e) {
        alertSubject.next({
          title: translate("map.resourceMappingNotFoundInFloor"),
        });
      }
    }

    selectDefaultFloorPlan(mapSite, options);
  };

  const setFloorList = (map: Site) => {
    if (map?.floorPlanList) {
      map.floorPlanList.sort((a, b) => Number(a?.id) - Number(b?.id));
      setFloors(map.floorPlanList);
    }
  };

  const getResourceChangeNotifications = () =>
    listenerService.listen(
      RESOURCE_MAPPING_STATE_CHANGE_HUB,
      (msg) => {
        const resourceMappingStateChangeData: ResourceMappingStateChangeData =
          JSON.parse(msg);
        setResourceMappingStateChange(resourceMappingStateChangeData);
      },
      "mapzone"
    );

  useEffect(() => {
    if (mapSiteId) {
      MapService.getSiteById(mapSiteId).then((mapSite) => {
        if (mapSite) {
          const options = getOptions(mapSite);
          setDropdownOptions(options);
          const mapSiteDto: Site = {
            name: "",
            floorPlanList: mapSite,
          };
          setFloorList(mapSiteDto);
          fetchFloorPlan(mapSiteDto, options);
          // After fetching site, prepare notifications for State Changes
          getResourceChangeNotifications();
        }
      });
    }

    return () => {
      listenerService.stopListening(
        RESOURCE_MAPPING_STATE_CHANGE_HUB,
        "mapzone"
      );
    };
  }, [mapSiteId]);

  function NoImageAvailable(): JSX.Element {
    return (
      <div data-cr="op-floor-plan-no-image" className="op-floor-plan-no-image">
        {translate("map.noFloorPlanImage")?.toUpperCase()}
      </div>
    );
  }

  const handleSelectOption = (option) => {
    setSelectedLocation(option);
    if (option?.value !== GEO_VALUE) {
      const newSelectedFloor = floors?.find((f) => f.id === option?.value);
      handleSelectFloor(newSelectedFloor);
      setIsGeo(false);
    } else {
      setSelectedFloorPlan(null);
      setIsGeo(true);
    }
  };

  return (
    <div className="map-zone compass-rounded-corner" data-cr="map-zone">
      <div
        style={{
          display: "flex",
          background: "var(--primary)",
          justifyContent: "center",
          width: "100%",
        }}
      />

      <>
        <div className="map-floors" data-cr="map-floors-header">
          <span>{translate("map.location")}</span>
          <CompassDropdown
            options={dropdownOptions}
            isLoading={isLoading}
            handleOptionSelect={handleSelectOption}
            selectedOption={selectedLocation}
            placeholder={translate("map.chooseLocation")}
          />
          <Stack direction="row" alignItems="center">
            <Switch checked={showLabels} onChange={handleShowLabels} />
            <FormLabel>
              {translate("labels", { ns: LocalizationNS.SHARED })}
            </FormLabel>
          </Stack>
        </div>
        <div className="map-container compass-rounded-corner">
          {isLoading && (
            <>
              <div className="map-loading" data-cr="map-loading">
                <Spinner size="large" />
              </div>
            </>
          )}
          <ErrorBoundary fallback={null}>
            {!isLoading &&
              (selectedFloorPlan?.floorPlanImage || isGeo ? (
                <MapBoxComponent
                  incidentSiteId={mapSiteId}
                  floorPlan={selectedFloorPlan}
                  isGeo={isGeo}
                  isTest={isTest}
                  resourceMappingId={resourceMappingId}
                  resourceMappingStateChange={resourceMappingStateChange}
                  mapBoxToken={MAPBOX_TOKEN}
                  resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
                  selectedFloorPlanDevice={selectedFloorPlanDevice}
                  imageInfo={imageInfo}
                  ogExecutionId={assignedIncident.operatorGuideExecutionId}
                  showLabels={showLabels}
                />
              ) : (
                <NoImageAvailable />
              ))}
          </ErrorBoundary>
        </div>
      </>
    </div>
  );
};

MapZone.defaultProps = {
  isTest: false,
  mapSiteId: "",
  resourceMappingId: "",
};

export default MapZone;
