// Generic
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
// Styles
import "./genericInfoCardTask.module.css";
// Components
import { MediaInfo, parseDate, User } from "compass-commons";
import { ResourceIcon } from "dms-lib";
import InfoFieldPhoto from "./infoFieldsData/InfoFieldPhoto";
import InfoFieldText from "./infoFieldsData/InfoFieldText";
import InfoFieldUrl from "./infoFieldsData/InfoFieldUrl";
// Models
import {
  InfoFieldDTO,
  InfoFieldType,
} from "../../../../../model/OG/InfoFieldDTO";
import { InfoFieldPhotoDTO } from "../../../../../model/OG/InfoFieldPhotoDTO";
import { InfoFieldTextDTO } from "../../../../../model/OG/InfoFieldTextDTO";
import { InfoFieldUrlDTO } from "../../../../../model/OG/InfoFieldUrlDTO";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import OperatorGuideService from "../../../../../services/OperatorGuideService";
import ImageGallery from "../../../../commons/imageGallery/ImageGallery";
import TaskUserBadge from "../taskUserBadge/TaskUserBadge";
import { handleError } from "../../../../../utils/request";
import { QueryKeys } from "../../../../../utils/constants";

interface GenericInfoCardTaskProps<T extends InfoFieldDTO> {
  infoFieldDTOs: T[];
  stepId: string;
  user?: User;
  clearedTime?: string;
  iconName?: string;
  heading?: string;
}

const GenericInfoCardTask = <T extends InfoFieldDTO>({
  infoFieldDTOs,
  stepId,
  user,
  clearedTime,
  iconName,
  heading,
}: GenericInfoCardTaskProps<T>): JSX.Element => {
  const { stateService } = useGlobalContext();
  const executionId = stateService.currentExecutionId.getValue();
  const queryClient = useQueryClient();

  const [galleryMedia, setGalleryMedia] = useState(null);

  const fetchSnapshot = async (index: number) => {
    try {
      await queryClient.fetchQuery(
        [QueryKeys.snapshot, executionId, stepId, index],
        () => OperatorGuideService.getSnapshots(executionId, stepId, [index])
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleImageClick =
    (field: InfoFieldPhotoDTO, index: number) => (media: MediaInfo) => {
      if (field.fieldPhotoStatus === "NOT_REQUESTED") {
        fetchSnapshot(index);
        return;
      }
      setGalleryMedia({
        fileId: field.fileId,
        resourceMappingName: field.resourceMapping?.name,
        mediaTimestamp: media?.mediaTimestamp,
      });
    };

  const initialData: Record<"photo" | "info", any[]> = { photo: [], info: [] };
  const data = infoFieldDTOs.reduce((acc, curr, index) => {
    switch (curr.type) {
      case InfoFieldType.PHOTO: {
        const field = curr as unknown as InfoFieldPhotoDTO;
        acc.photo.push(
          <InfoFieldPhoto
            infoFieldPhoto={field}
            onImgClick={(val) => handleImageClick(field, index)(val)}
          />
        );
        break;
      }
      case InfoFieldType.TEXT: {
        const field = curr as unknown as InfoFieldTextDTO;
        acc.info.push(
          <InfoFieldText infoFieldText={field} date={clearedTime} />
        );
        break;
      }
      case InfoFieldType.URL: {
        const field = curr as unknown as InfoFieldUrlDTO;
        acc.info.push(<InfoFieldUrl infoFieldUrl={field} />);
        break;
      }
      default:
        break;
    }
    return acc;
  }, initialData);

  return (
    <>
      <div className="operation-geninfo-card__wrapper">
        <div className="operation-geninfo-card__container">
          <div className="operation-geninfo-card__icon">
            {user ? (
              <TaskUserBadge user={user} />
            ) : (
              <ResourceIcon src={iconName || "badge"} />
            )}
          </div>
          {data.photo.length > 0 && (
            <div>
              <div className="operation-geninfo-card__heading">
                <p>{heading}</p>
                <p>{parseDate(clearedTime)}</p>
              </div>
              <div
                className={`operation-geninfo-card__media operation-geninfo-card__images--${
                  data.info.length ? "list" : "gallery"
                }`}
              >
                {...data.photo}
              </div>
            </div>
          )}
          {data.info.length > 0 && (
            <div className="operation-geninfo-card__info">{...data.info}</div>
          )}
        </div>
      </div>
      {galleryMedia && (
        <ImageGallery
          isOpen={!!galleryMedia}
          onClose={() => setGalleryMedia(null)}
          mediaFileShort={galleryMedia}
        />
      )}
    </>
  );
};

export default GenericInfoCardTask;
