import { useEffect } from "react";
import { OperationGuideStateChangeData } from "../model/OG/OperationGuideStateChangeData";
import { queryClient } from "../utils/request";
import { useGlobalContext } from "../contexts/GlobalContext";
import { OGExecutionTaskStepBase } from "../model/OG/OGExecutionTaskStepBase";
import { QueryKeys } from "../utils/constants";
import OperatorGuideService from "../services/OperatorGuideService";

const { OPERATOR_GUIDE_CHANGE_STATE_HUB } = appConfig;

const useListenOGStateChange = () => {
  const { stateService, listenerService } = useGlobalContext();
  const { currentExecutionId } = stateService;

  const updateStepsList = (
    currentSteps: Array<OGExecutionTaskStepBase>,
    newStep: OGExecutionTaskStepBase
  ): Array<OGExecutionTaskStepBase> => {
    const existingStepIndex = currentSteps.findIndex(
      (step) => step.id === newStep.id
    );

    if (existingStepIndex !== -1) {
      return currentSteps.map((step, index) =>
        index === existingStepIndex ? { ...newStep } : step
      );
    }
    return [...currentSteps, newStep];
  };

  const refreshExecutionStepId = async (stepId: string) => {
    const myCurrentExecution = stateService.currentExecutionId.getValue();
    const newStep: OGExecutionTaskStepBase = await queryClient.fetchQuery(
      [QueryKeys.executionStepId, stepId],
      () =>
        OperatorGuideService.getExecutionStepById(myCurrentExecution, stepId)
    );

    const currentExecution = stateService.currentOgExecution.getValue();
    stateService.currentOgExecution.next({
      ...currentExecution,
      registryResponse: {
        ...currentExecution.registryResponse,
        stepsList: updateStepsList(
          currentExecution.registryResponse.stepsList,
          newStep
        ),
      },
    });
  };

  const processHubMessage = (msg: any) => {
    try {
      const message: OperationGuideStateChangeData = JSON.parse(msg);
      refreshExecutionStepId(message.stepId).then();
    } catch (e) {
      console.error("Error parsing message from hub", e);
    }
  };

  useEffect(() => {
    const currentExecutionIdSub = currentExecutionId.subscribe((val) => {
      if (!val) return;
      if (listenerService.isListening(OPERATOR_GUIDE_CHANGE_STATE_HUB)) {
        listenerService.stopListening(OPERATOR_GUIDE_CHANGE_STATE_HUB);
      }

      listenerService.listen(
        OPERATOR_GUIDE_CHANGE_STATE_HUB,
        processHubMessage,
        "",
        val
      );
    });

    return () => {
      currentExecutionIdSub.unsubscribe();
    };
  }, []);
};

export default useListenOGStateChange;
