import { OGExecutionGenericInfo } from "./OGExecutionGenericInfo";
import { OGExecutionTaskStepBase } from "./OGExecutionTaskStepBase";

// eslint-disable-next-line no-shadow
enum ExecutionTaskType {
  ManualTask = "MANUAL_TASK",
  Comment = "COMMENT",
  Attachment = "ATTACHMENT",
  ResourceInteraction = "RESOURCE_INTERACTION",
  GenericInfo = "GENERIC_INFO",
  RecordedVideo = "RECORDED_VIDEO",
  AdditionalActivation = "ADDITIONAL_ACTIVATION",
}

const isGenericInfo = (
  step: OGExecutionTaskStepBase
): step is OGExecutionGenericInfo => {
  return step.type === ExecutionTaskType.GenericInfo;
};

export { ExecutionTaskType, isGenericInfo };
