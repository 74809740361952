import React, { useEffect, useRef, useState } from "react";
import { useI18n } from "compass-commons";
import { Button } from "dms-lib";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import DatabaseSearchResult from "../../../../../model/databaseSearch/internal/DatabaseSearchResult";

interface DatabaseSearchActionProps {
  taskState: TaskStepState;
  actionCallBack: (selectedOption: DatabaseSearchResult) => void;
  selectedOption: DatabaseSearchResult;
}

const DatabaseSearchAction = (props: DatabaseSearchActionProps) => {
  const { taskState, actionCallBack, selectedOption } = props;
  const { t: translate } = useI18n();
  const [recentRequest, setRecentRequest] = useState<boolean>(false);

  // Ref to store the timeout identifier
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Clears the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className={taskState}
      style={{ float: "right" }}
      data-cr="database-search-action"
    >
      <Button
        dataCr="search-task-button"
        color="primary"
        variant="contained"
        onClick={() => {
          // This verification avoids multiple requests from clicks made subsequently.
          // This would raise a 'Generic error' to appear to the Operator
          if (!recentRequest) {
            setRecentRequest(true);
            actionCallBack(selectedOption);
            timeoutRef.current = setTimeout(
              () => setRecentRequest(false),
              2000
            );
          }
        }}
        size="small"
        disabled={
          TaskStepState.COMPLETED === taskState ||
          (TaskStepState.CURRENT === taskState && !selectedOption)
        }
      >
        <span>
          {translate("incident.operatorGuide.dbSearchTask.doneButton")}
        </span>
      </Button>
    </div>
  );
};

export default DatabaseSearchAction;
