import OperatorGuideService from "../services/OperatorGuideService";
import { ACTIVATIONS_INITIAL_VALUE } from "../services/StateService";
import { useGlobalContext } from "../contexts/GlobalContext";
import { SocketAssignedIncidentDTO } from "../model/incident/SocketAssignedIncident";
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";

const useAdditionalActivations = () => {
  const { stateService } = useGlobalContext();
  const { incidentAdditionalActivations } = stateService;

  const handleAdditionalActivations = (
    incident: SocketAssignedIncidentDTO | AssignedIncidentResponse
  ) => {
    if (incident.additionalActivationsCount <= 0) return;

    OperatorGuideService.getAdditionalActivations(incident.id).then((res) => {
      incidentAdditionalActivations.next({
        ...incidentAdditionalActivations.value,
        additionalActivationsCount: incident.additionalActivationsCount,
        additionalActivations: res,
      });
    });
  };

  const resetAdditionalActivations = () => {
    incidentAdditionalActivations.next(ACTIVATIONS_INITIAL_VALUE);
  };

  return {
    handleAdditionalActivations,
    resetAdditionalActivations,
  };
};

export default useAdditionalActivations;
