import { MediaInfo, ResourceMapping } from "compass-commons";
import { InfoFieldDTO, InfoFieldType } from "./InfoFieldDTO";

export interface InfoFieldPhotoShort {
  fileId: string;
  resourceMappingName?: string;
  mediaTimestamp?: string;
}
export interface InfoFieldPhotoDTO extends InfoFieldDTO {
  fileId: string | null;
  resourceMapping?: ResourceMapping;
  mediaFileDTO: MediaInfo | null;
  fieldPhotoType: "SNAPSHOT" | "GENERIC";
  fieldPhotoStatus: "NOT_REQUESTED" | "REQUESTED";
  desiredTimestamp: string;
  recorded: boolean;
}

export const isOfTypePhoto = (
  infoField: InfoFieldDTO
): infoField is InfoFieldPhotoDTO => infoField.type === InfoFieldType.PHOTO;

export const hasMedia = (
  infoField: InfoFieldDTO
): infoField is InfoFieldPhotoDTO =>
  infoField.type === InfoFieldType.PHOTO &&
  !!(infoField as InfoFieldPhotoDTO).mediaFileDTO;
